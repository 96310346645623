import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, lastValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthHelper } from '../helpers/authHelper.provider';
import { AUTH_TOKEN_KEY } from './auth.constants';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private noAuthRedirectRoutes: string[] = ['/review/overview', '/review-overview'];

  constructor(private _authService: AuthHelper, private _router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(
      this._authService.getAccessToken().then((token) => {
        let headers = req.headers;
        if (!token && !this.urlHasToken()) {
          this._authService.login();
          return lastValueFrom(next.handle(req));
        }

        if (headers.keys().length > 0) {
          headers = req.headers
            .append('Authorization', `Bearer ${token}`)
            .append('Content-Type', 'application/json');
        } else {
          headers = new HttpHeaders()
            .set('Authorization', `Bearer ${token}`)
            .set('Content-Type', 'application/json');
        }

        if (headers.has(AUTH_TOKEN_KEY)) {
          const encodedToken = encodeURIComponent(headers.get(AUTH_TOKEN_KEY));
          headers = headers.set(AUTH_TOKEN_KEY, encodedToken);
          headers = headers.delete('Authorization');
        }

        const authReq = req.clone({ headers });
        return lastValueFrom(
          next.handle(authReq).pipe(
            tap({
              error: (error) => {
                const respError = error as HttpErrorResponse;
                if (respError && respError.status === 401 && !this.shouldBypass(req)) {
                  this._router.navigate(['unauthorized']);
                }
                throw error;
              },
            })
          )
        );
      })
    );
  }

  private urlHasToken(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('token');
  }

  private shouldBypass(req: HttpRequest<any>): boolean {
    return this.noAuthRedirectRoutes.some((path) => req.url.includes(path));
  }
}
